import React from "react";
import Logosuniti from "../images/LOGO-Sj.jpg";
import { Link } from "react-router-dom";


import Rate from "../pages/Rate";

import "./Navbar.css";

function Navbar(){
    return(
        <>
<div className="fixed-top">
<div className="suniti_jewellers">
        <div className="album py-0">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-10" >
            <div className="d-flex justify-content-between align-items-center">
            <marquee>
             <h4 className="mt-2" style={{color:"maroon"}} > Welcome to Suniti Jewellers</h4>
             </marquee>
    
                               
           
          
                               
                        
                            </div>
                               
                            </div>
                          
                            <div className="d-flex justify-content-between align-items-center">
                           


                
                         
                            </div>
                            </div>
                            </div>
                            </div>
        </div>
<nav className="navbar navbar-expand-lg navbar-white bg-white shadow">
  <div className="container-fluid">
  <Link to="/" className="navbar-brand fw-bold  fs-4" >
   
  <img src={Logosuniti} className="img-fluid"  width="120" height="27" alt="Logos"/>          
  </Link>
  <Rate/>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav mx-auto mb-1 mb-lg-0">
        <li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" >Home</Link>
        </li>
   
       
       
        <li className="nav-item dropdown">
          <a  style={{color:"black"}} className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        Product
          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/ring" className="dropdown-item" >Ring</Link></li>
            <li><Link to="/chain" className="dropdown-item" >Chain</Link></li>
            <li><Link to="/dul" className="dropdown-item" >Dul</Link></li>
            <li><Link to="/sankha" className="dropdown-item" >Sankha</Link></li>
            <li><Link to="/pola" className="dropdown-item" >Pola</Link></li>
            <li><Link to="/necklace" className="dropdown-item" >Necklace</Link></li>
         
            <li><Link to="/jhumka" className="dropdown-item" >Jhumka</Link></li>
            <li><Link to="/chik" className="dropdown-item" >Chik</Link></li>
            <li><Link to="/choker" className="dropdown-item" >Choker</Link></li>
            <li><Link to="/eartop" className="dropdown-item" >Ear Top</Link></li>
            <li><Link to="/bangles" className="dropdown-item" >Bangles</Link></li>
      
            <li><hr className="dropdown-divider"/></li>
         
          </ul>
        </li>
        <li className="nav-item">
          <Link to="/babybracelet" className="nav-link active" aria-current="page" >Baby Bracelet</Link>
        </li>
        <li className="nav-item">
          <Link to="/Babyring" className="nav-link active" aria-current="page" >Baby Ring</Link>
        </li>
     
      
  
        <li className="nav-item">
          <Link to="/offer" className="nav-link active" aria-current="page" >Offer</Link>
        </li>
        <li className="nav-item">
          <Link to="/contact" className="nav-link active" aria-current="page" href="#">Contact</Link>
        </li>
       
      </ul>
      <a href="tel: +91 9732703443"  className="btn btn-light mb-2">    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z"/>
</svg> +91 9732703443</a>
    </div>
  </div>
</nav>
</div>
        </>
    );
}
export default Navbar;