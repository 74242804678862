import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'
import Navbar from '../inc/Navbar';
import {Link} from "react-router-dom";





import ScrollToTop from "react-scroll-to-top";




function Offer () {
 
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [offer, setOffer] = useState([])
      const navigate = useNavigate()
  
      const offerCollectionRef = collection(db, "offer");
      useEffect(() => {
  
          const getOffer = async () => {
              const data = await getDocs(offerCollectionRef);
              setOffer(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getOffer()
      }, [])
    return (
      
<>
<Navbar/>

<br/>


<br/>


<ScrollToTop smooth top="100" color="Darkblue" />

<br/>
<br/>
<br/>
<br/>




<div className="album py-1">
        
            <div className="container">

                <div className="row">
               
                <div className="col-md-6">
                        
                        <div className="card-body">
                        <input type="text" className="form-control" placeholder="Search" name="location" 
                        onChange ={(e) => {
                          setsearchTerm(e.target.value);
                        }}
                        />
                                <div className="d-flex justify-content-between align-items-center">
                                   
                                    
                            

                            </div>
                          
                    
                        </div>

                      
                    </div>
             
                    </div>
                  
                    </div>
                    </div>
<div className="album py-4">
        
        <div className="container">

            <div className="row">
            {offer.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        val.des.toLowerCase().includes(searchTerm.toLowerCase()) 
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((sunitijewellers) => { return (
       
            <div className="col-md-4 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                        <img  className="card-img-top" src={sunitijewellers.img}  alt="offers"/>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>{sunitijewellers.title}</b></h6>
                               
                                <p className="card-text">{sunitijewellers.des}</p>
                               
                                      
                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                            </div>
                               
                            </div>
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={offer.length}
                /> */}
                            </div>
                            </div>

</>
        
        );
    }
    
    export default Offer;