
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Ring from "./components/pages/Ring";
import Jhumka from "./components/pages/Jhumka";
import Chain from "./components/pages/Chain";
import Necklace from "./components/pages/Necklace";
import Dul from "./components/pages/Dul";
import Bala from "./components/pages/Bala";
import Pola from "./components/pages/Pola";
import Sankha from "./components/pages/Sankha";
import Chik from "./components/pages/Chik";
import Choker from "./components/pages/Choker";
import Eartop from "./components/pages/Eartop";
import Morep from "./components/pages/Morep";
import Rate from "./components/pages/Rate";
import Bangles from "./components/pages/Bangles";
import Offer from "./components/pages/Offer";
import Contact from "./components/pages/Contact";
import Footer from "./components/inc/Footer";
import Babybracelet from './components/pages/Babybracelet';
import Ladiesbracelet from './components/pages/Ladiesbracelet';
import Gentsbracelet from './components/pages/Gentsbracelet';
import Babyring from './components/pages/Babyring';
import Ladiesring from './components/pages/Ladiesring';
import Gentsring from './components/pages/Gentsring';
function App() {
  return (
       
    <Router>
      
  
  
        <div>
      
         
          
          <Routes>
     
         
          <Route axact path="/" element={<Home/>}/>
          <Route axact path="/ring" element={<Ring/>}/>
             <Route axact path="/jhumka" element={<Jhumka/>}/>
             <Route axact path="/Chain" element={<Chain/>}/>
             <Route axact path="/dul" element={<Dul/>}/>
             <Route axact path="/earring" element={<Bala/>}/>
             <Route axact path="/pola" element={<Pola/>}/>
             <Route axact path="/sankha" element={<Sankha/>}/>
             <Route axact path="/chik" element={<Chik/>}/>
             <Route axact path="/choker" element={<Choker/>}/>
             <Route axact path="/eartop" element={<Eartop/>}/>
             <Route axact path="/bangles" element={<Bangles/>}/>
             
             <Route axact path="/necklace" element={<Necklace/>}/>
             <Route axact path="/Moreproduct" element={<Morep/>}/>
              <Route axact path="/rate" element={<Rate/>}/>
              <Route axact path="/offer" element={<Offer/>}/>
          <Route axact path="/contact" element={<Contact/>}/>
          <Route axact path="/babybracelet" element={<Babybracelet/>}/>
          <Route axact path="/ladiesbracelet" element={<Ladiesbracelet/>}/>
          <Route axact path="/Gentsbracelet" element={<Gentsbracelet/>}/>
          <Route axact path="/Babyring" element={<Babyring/>}/>
          <Route axact path="/Ladiesring" element={<Ladiesring/>}/>
          <Route axact path="/Gentsring" element={<Gentsring/>}/>
          
          </Routes>
    <Footer/>
  
    </div>
  
    </Router>
  );
}

export default App;
