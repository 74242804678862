import React from "react";

import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import {Link} from "react-router-dom";

import "./Morep.css";

function Morep (){
    return(
            <>
           

           <Navbar/>
           <br/>
       <br/>
       <br/>
       <br/>
           <Slider/>

           <br/>

            
        <div className="album py-2">
        <div className="container">

            <div className="row">
            
           <h3><b> Website will be update...</b></h3>
              </div>
              
              </div>
              </div>
            
           
             
            </>
    );
}


export default Morep;