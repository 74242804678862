import React from 'react';



function Contact() {
    return (
      
<>


   
<div className="album py-1">
        <div className="container">

            <div className="row">

            <iframe className="iframe-fluid" height="400" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Suniti%20Jewellers,%20Surendra%20Market%20Road,%20Bazaar%20Para,%20Karimpur,%20West%20Bengal%20741152%20+()&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>

                </div>
                </div>
                </div>
       

</>
        
        );
    }
    
    export default Contact;