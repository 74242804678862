import React, { useState, useEffect } from 'react';
import { useNavigate} from 'react-router-dom';
import { collection, getDocs, } from "firebase/firestore";
// import PaginationOld from '../pages/PaginationOld';
import {db} from '../../firebase'




function Rate () {
 

    const [ setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
      const [showPerPage, setShowPerPage] = useState(9);
      const [pagination, setPagination] =useState({
          start: 0,
          end: showPerPage,
      });
  
      const onPaginationChange = (start,  end) =>{
          setPagination({start: start, end: end});
      };
      const [searchTerm, setsearchTerm] = useState("");
      const [rate, setRate] = useState([])
      const navigate = useNavigate()
  
      const rateCollectionRef = collection(db, "rate");
      useEffect(() => {
  
          const getRate = async () => {
              const data = await getDocs(rateCollectionRef);
              setRate(data.docs.map((doc) => ({...doc.data(), id: doc.id })))
          }
  
          getRate()
      }, [])
    return (
      
<>


            <div className="row">
            {rate.filter((val)=>{
      if (setsearchTerm === ""){
        return val;
      } else if (
        val.date.toLowerCase().includes(searchTerm.toLowerCase()) 
      
      
       
  
      ){
        return val;
      }
    }).slice(pagination.start, pagination.end).map((sunitijewellers) => { return (
       
            <div className="col-md-12 ">
                           <h6 style={{color:"maroon"}} className="card-text mb-0"> <b> Date:- {sunitijewellers.date}</b></h6>
                              <div className='fontnav'>
                                <p  style={{color:"Darkblue"}} className="card-text mb-0 me-1"> <small>Today's Gold Rate: ₹{sunitijewellers.todayrate}</small></p>
                          </div>
                          


                                <div className="d-flex justify-content-between align-items-center">
                              
                               
                              
                            </div>
                            </div>
                          
                               
                        
                                )})} 
                            </div>
                            {/* <PaginationOld showPerPage={showPerPage} 
                onPaginationChange={onPaginationChange}
                total={rate.length}
                /> */}
                            
</>
        
        );
    }
    
    export default Rate;