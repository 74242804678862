
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyCjBS2skRSmb1BZUyJrRjF0C_6wADeXN0k",
  authDomain: "suniti-jewellers.firebaseapp.com",
  projectId: "suniti-jewellers",
  storageBucket: "suniti-jewellers.appspot.com",
  messagingSenderId: "363966607099",
  appId: "1:363966607099:web:39d7488b42887eb2b28a5e",
  measurementId: "G-441ZTJQDWL"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;