import React from "react";
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import { Link } from "react-router-dom";
import ShowroomJewellers from "../images/showroom.jpg"
import "./Home.css";
import Chaingold from "../images/chain.jpg"
import Ring from "../images/ring.jpg"
import Earring from "../images/earring.jpg"
import Dul from "../images/dul.jpg"
import Offer from "../images/OFFER.jpg";
import Pola from "../images/pola.jpg"
import Chik from "../images/chik_jewellers.jpg";
import Choker from "../images/choker.jpg";

import Bangle from "../images/bangle.jpg";
import Eartop from "../images/eartop.jpg";

import Sankha from "../images/sankha.jpg"
import Nacklace from "../images/Nacklece.jpg"
import Jhumka from "../images/jhumka.jpg"
import ScrollToTop from "react-scroll-to-top";
import Googlemap from "../pages/Googlemap";
import Babybracelet from "../images/Babybracelet.jpg";
import Ladiesbracelet from "../images/LADIESBRACELET.jpg";
import Gentsbracelet from "../images/Gentsbracelet.jpg";
import Babyring from "../images/babay-rings.jpg";
import Ladiesring from "../images/Ladiesring.jpg";
import Gentsring from "../images/Gents Ring.jpg";


import {
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
  FacebookIcon, TwitterShareButton, EmailShareButton, EmailIcon,

  LinkedinShareButton,
  LinkedinIcon,
  TwitterIcon,
} from "react-share";

function Home() {
  return (
    <>
      <Navbar />
      <br />
      <br />
      <br />

      <div className="album py-0">
        <div className="container">

          <div className="row">
            <h5>Suniti Jewellers Karimpur</h5>
          </div>
        </div>
      </div>
      <ScrollToTop smooth top="100" color="Darkblue" />
      <Slider />
<br/>
      {/* <div className="rates">
        <Rate/>
        </div> */}
      <div className="album py-2">
        <div className="container">

          <div className="row">

            <div className="col-md-6" >

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box">

                  <Link to="/chain">
                    <img src={Chaingold} className="card-img-top" alt="Chaingold" /></Link>


                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <center>
                        <p style={{ color: "DarkSlateBlue" }} className="card-text mb-0"><b>Chain</b></p></center>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/chain" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/chain" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/chain" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="//https://www.sunitijewellers.com/chain" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="//https://www.sunitijewellers.com/chain" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>

                  <center>
                    <Link to="/chain">
                      <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                      </center>
                    </Link>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>

                  </center>
                </div>


                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/earring">
                    <img src={Earring} className="card-img-top" alt="Earring" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue	 " }} className="card-text mb-2"><b>Ear Ring</b></p>
                      <Link to="/earring">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/earring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/earring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/earring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/earring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/earring" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>
                  <Link to="/earring">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/ring">
                    <img src={Ring} className="card-img-top" alt="Ring" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Ring</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/ring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/ring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/ring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/ring" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/ring" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>
                  <Link to="/ring">


                    <center>

                      <button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


                <div className="card mb-4 me-3 border-dark box shadow">

                  <Link to="/dul">
                    <img src={Dul} className="card-img-top" alt="Others" />
                  </Link>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b> Dul</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/dul" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/dul" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/dul" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/dul" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/dul" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                  <Link to="/dul" target="_blank" rel="noopener noreferrer">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>

              </div>
            </div>
            <div className="col-md-6" >

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box">

                  <Link to="/pola">
                    <img src={Pola} className="card-img-top" alt="Chaingold" /></Link>


                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Pola</b></p>


                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/pola" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/pola" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/pola" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/pola" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/pola" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>




                    </div>
                  </div>

                  <center>
                    <Link to="/pola">
                      <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                      </center>
                    </Link>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>

                  </center>
                </div>


                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/sankha">
                    <img src={Sankha} className="card-img-top" alt="sankha" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue	 " }} className="card-text mb-2"><b>Sankha</b></p>


                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/sankha" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/sankha" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/sankha" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/sankha" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/sankha" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>


                    </div>
                  </div>
                  <Link to="/sankha">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box">

                  <Link to="/necklace">
                    <img src={Nacklace} className="card-img-top" alt="Nacklace" /></Link>


                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Necklace</b></p>


                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/Necklace" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/Necklace" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/Necklace" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/Necklace" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/Necklace" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>




                    </div>
                  </div>

                  <center>
                    <Link to="/necklace">
                      <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                      </center>
                    </Link>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>

                  </center>
                </div>


                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/jhumka">
                    <img src={Jhumka} className="card-img-top" alt="Jhumka" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue	 " }} className="card-text mb-2"><b>Jhumka</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/jhumka" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/jhumka" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/jhumka" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/jhumka" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/jhumka" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                  <Link to="/Jhumka">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="album py-2">
        <div className="container">

          <div className="row">

            <div className="col-md-6" >

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box">

                  <Link to="/choker">
                    <img src={Choker} className="card-img-top" alt="Choker" /></Link>


                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Choker</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/choker" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/choker" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/choker" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/choker" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/choker" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <center>
                    <Link to="/choker">
                      <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                      </center>
                    </Link>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>

                  </center>
                </div>


                <div className="card mb-4   border-dark box shadow">
                  <Link to="/chik">
                    <img src={Chik} className="card-img-top" alt="chick" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue	 " }} className="card-text mb-2"><b>Chik</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/chik" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/chik" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/chik" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/chik" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/chik" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/chik">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>
                  </Link>

                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


              </div>

              <div className="d-flex justify-content-between align-items-center">
                <div className="card mb-4 me-3 border-dark box shadow">
                  <Link to="/bangles">
                    <img src={Bangle} className="card-img-top" alt="bangle" /></Link>
                  <div class="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Bangles</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/bangles" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/bangles" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/bangles" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/bangles" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/bangles" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div className="modal-footer">
                              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/bangles">


                    <center>

                      <button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>

                    </center>
                  </Link>
                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>


                <div className="card mb-4  border-dark box shadow">

                  <Link to="/eartop" target="_blank" rel="noopener noreferrer">
                    <img src={Eartop} className="card-img-top" alt="Eartop" />
                  </Link>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <p style={{ color: "DarkSlateBlue		" }} className="card-text mb-2"><b>Ear Top</b></p>
                      <Link to="/">

                      </Link>

                      {/* <!-- Button trigger modal --> */}
                      <button type="button" className="btn btn-dark btn-sm mb-2" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-share-fill" viewBox="0 0 16 16">
                          <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z" />
                        </svg>
                      </button>

                      {/* <!-- Modal --> */}
                      <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalLabel">Share Now </h5>
                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                              < FacebookShareButton url="/https://www.sunitijewellers.com/eartop" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <FacebookIcon size={37} round={true} />
                              </FacebookShareButton>

                              <  WhatsappShareButton url="/https://www.sunitijewellers.com/eartop" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <WhatsappIcon size={37} round={true} />
                              </ WhatsappShareButton>

                              <  TwitterShareButton url="/https://www.sunitijewellers.com/eartop" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <TwitterIcon size={37} round={true} />
                              </ TwitterShareButton>

                              <  LinkedinShareButton url="/https://www.sunitijewellers.com/eartop" className="me-2" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >

                                <  LinkedinIcon size={37} round={true} />
                              </ LinkedinShareButton>

                              <  EmailShareButton url="/https://www.sunitijewellers.com/eartop" quote={"Suniti Jewellers"} hastag={"#sunitijewellers"}
                              >
                                <  EmailIcon size={37} round={true} />
                              </ EmailShareButton>
                            </div>
                            <div class="modal-footer">
                              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link to="/eartop" target="_blank" rel="noopener noreferrer">

                    <center><button type="button" className="btn btn-outline-dark mb-2"> <small> Click Here </small></button>
                    </center>

                  </Link>

                  <center>
                    <h5 style={{ color: "Gray" }} className="card-title">Suniti Jewellers</h5>
                  </center>

                </div>

              </div>
            </div>




            <div className="col-md-6">
              <Link to="/offer">
                <img src={Offer} className="img-fluid mb-4" alt="Founder" />
              </Link>
              <h3 style={{ color: "Gray" }} className="card-text"><b>SINCE -  1988</b></h3>
              <div className="colors1">
              </div>
            </div>

          </div>
        </div>
      </div>
      <hr />



      <br />


      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-6 me-2 mt-3">
                  <a href='babybracelet'>
                    <div className="card">
                      <img src={Babybracelet} className="card-img-top" alt="Babybracelet" />
                      <div className="card-body">
                        <h5 style={{ color: "black" }} className="card-title mb-1">Baby Bracelet</h5>

                        <div className="btn-grad15" type="submit"><small>View Details</small> </div>

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6  mt-3">
                  <a href='ladiesbracelet'>
                    <div className="card">
                      <img src={Ladiesbracelet} className="card-img-top" alt="Ladiesbracelet" />
                      <div className="card-body">
                        <h5 style={{ color: "black" }} className="card-title mb-1">Ladies Bracelet</h5>

                        <div className="btn-grad15"><small>View Details</small> </div>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-6 me-2 mt-3">
                  <a href="Gentsbracelet">
                    <div className="card">
                      <img src={Gentsbracelet} className="card-img-top" alt="Gentsbracelet" />
                      <div className="card-body">
                        <h5 className="card-title mb-1">Gents Bracelet</h5>

                        <div className="btn-grad15"><small>View Details</small> </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6  mt-3">
                  <a href="Babyring">
                    <div className="card">
                      <img src={Babyring} className="card-img-top" alt="Babyring" />
                      <div className="card-body">
                        <h5 className="card-title mb-1" >Baby Ring</h5>

                        <div  className="btn-grad15"><small>View Details</small> </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="album py-0">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex justify-content-start align-items-center mb-2">
                <div className="col-md-6 me-2 mt-3">
                  <a href='Ladiesring'>
                    <div className="card">
                      <img src={Ladiesring} className="card-img-top" alt="Ladiesring" />
                      <div className="card-body">
                        <h5 style={{ color: "black" }} className="card-title mb-1">Ladies Ring</h5>

                        <div className="btn-grad15" type="submit"><small>View Details</small> </div>

                      </div>
                    </div>
                  </a>
                </div>
                <div className="col-md-6  mt-3">
                  <a href='Gentsring'>
                    <div className="card">
                      <img src={Gentsring} className="card-img-top" alt="Gentsring" />
                      <div className="card-body">
                        <h5 style={{ color: "black" }} className="card-title mb-1">Gents Ring</h5>

                        <div className="btn-grad15"><small>View Details</small> </div>
                      </div>
                    </div>
                  </a>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />

      <div className="backimage">
        <center>
          <div className="sentancestyle">
            <h1><b>SUNITI JEWELLERS</b></h1><br />
          </div>
          <div className="sentancestyles">
            <h3>Best Jewellery Collections in Karimpur</h3>
          </div>
        </center>
      </div>
   
      <div className="sunitibgcolor">
        <br />

        <center><h2 style={{ color: "maroon" }} className="mt-3"><b>OUR SHOWROOM</b></h2></center>
        <br />
        <div className="album py-0">

          <div className="container">

            <div className="row">

              <div className="col-md-6 mt-8" >

                <div className="sunitiimground">
                  <img src={ShowroomJewellers} className="card-img-top" alt="ShowroomJewellers" />
                </div>
                <div className="d-flex justify-content-between align-items-center">


                </div>

              </div>
              <div className="col-md-5 mt-3" >

                <h6 style={{ color: "maroon" }} className="card-text"> <b>SUNITI JEWELLERS</b></h6>
                <p style={{ color: "maroon" }} className="card-text mb-2">
                  Suniti Jewellers - নতুন রুপে নতুন সাজে </p>

                <p style={{ color: "maroon" }} className="card-text mb-2">
                  SINCE : 1988 </p>
                <p style={{ color: "maroon" }} className="card-text">
                  স্বল্প বাজেট অনুযায়ী সরকার অনুমোদিত হলমার্ক গহনা বিক্রেতা
                </p>


                <div className="d-flex justify-content-between align-items-center">

                </div>

              </div>
            </div>
          </div>
        </div>
        <br />
        <hr />
        <Googlemap />
        <br />
      </div>



    </>
  );
}

export default Home;