import React from "react";
import Babbracelet from "../images/Baby Bracelet design.jpg";
import Sunitijewellerskarimpur from "../images/Sunitijewellers.jpg";
import Sunitishowroom from "../images/suniti-slide.jpg";
import Sunitijewellers from "../images/suniti-jewellers.jpg";
import suniti_patahar from "../images/suniti_patahar.jpg";

function Slider(){
    return(
        <>
            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>



  </div>
  
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={Babbracelet} className="d-block w-100" alt="Babbracelet"/>
    </div>
    <div className="carousel-item">
      <img src={Sunitijewellers} className="d-block w-100" alt="suniti_patahar"/>
    </div>
    <div className="carousel-item">
      <img src={Sunitijewellerskarimpur} className="d-block w-100" alt="Sunitijewellers"/>
    </div>
    <div className="carousel-item">
      <a href="tel: +91 7908168813">
      <img src={Sunitishowroom} className="d-block w-100" alt="Sunitijewellers"/>
      </a>
    </div>
    <div className="carousel-item">
      <img src={suniti_patahar} className="d-block w-100" alt="suniti_patahar"/>
    </div>

    
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>

        </>
    );
}

export default Slider;